<template>
  <v-btn
    @click="$emit('click')"
    color="error"
    :class="{ 'mr-n7 mt-n4' : overflow }"
    :depressed="depressed"
    :top="top"
    absolute
    x-small
    right
    fab
  >
    <v-icon>mdi-close</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    depressed: Boolean,
    overflow: Boolean,
    top: Boolean,
  }
}
</script>